.core-layout__nav {
	background: linear-gradient(to bottom, $light-blue, $dark-blue);
	padding: 4rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100vh;
	img.logo {
		margin-bottom: 4rem;
	}
	&-avatar {
		width: 5rem;
		height: 5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		font-weight: bold;
		color: $white;
		margin-bottom: 0.8rem;
		background: linear-gradient(to bottom, $white, #7da9ef);
	}
	&-user {
		color: $white;
	}

	&-menu {
		margin: 4rem 0;
		padding-left: 2rem;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		overflow-y: auto;
		overflow-x: hidden;

		.menu-item {
			display: flex;
			align-items: center;
			padding: 1.2rem 2.4rem;
			position: relative;
			cursor: pointer;
			border-top-left-radius: $radius-small;
			border-bottom-left-radius: $radius-small;
			max-height: 5rem;
			.menu-icon {
				margin-right: 2rem;
				width: 4rem;
				display: flex;
				justify-content: center;
			}
			span {
				color: $white;
			}
			&.active {
				background: linear-gradient(
					to right,
					rgba(0, 79, 143, 1),
					rgba(53, 165, 222, 1)
				);
			}
			&.disabled {
				cursor: not-allowed;
				span {
					color: $dark-gray !important;
				}
			}
		}
	}
	.button-container {
		border: 1px solid $white;
		border-radius: $radius-small;
		overflow: hidden;

		button {
			background: $dark-blue;
		}
	}
	&__localization {
		margin-top: 24px;
		display: flex;
		justify-content: center;
		gap: 32px;
	}
}
