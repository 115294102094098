.attachment-item {
	display: flex;
	align-items: center;
	margin: 0 0.8rem 0.8rem;
	position: relative;
	cursor: pointer;
	.icon {
		color: $light-blue;
	}
	&:hover {
		.close {
			display: block;
		}
	}
	.close {
		display: none;
		font-size: 1.6rem;
		color: $red;
		position: absolute;
		right: -1rem;
		top: -1rem;
	}
}
