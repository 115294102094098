.admin-dashboard {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	&__header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1.2rem;
		&-content {
			display: flex;
			align-items: flex-end;
			flex: 5;
			h1 {
				margin: 0 1.2rem -0.25em 0;
			}
			.button-text {
				padding: 0.8rem;
			}
			.filter-popover {
				margin: 0 1.2rem;
			}
		}

		.time-header {
			flex: 3;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			&__last-login {
				display: flex;
				margin-top: 0.8rem;
				.login-text {
					font-weight: bold;
					margin-right: 1.6rem;
				}
				.login-time {
					display: flex;

					span {
						color: #093cc7;
						margin: 0 0.4rem;
						font-weight: bold;
					}
				}
			}
		}
	}
	&__content {
		flex: 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		&-row {
			display: flex;
			overflow: hidden;

			&.row1 {
				.admin-dashboard__tickets {
					@include card-format();
					flex-direction: row;
					justify-content: space-evenly;
					flex: 4;
					.tickets-line-chart {
						flex: 1;
						display: flex;
						flex-direction: column;
						&__header {
							display: flex;
							align-items: center;
							h2 {
								margin-right: 1.2rem;
							}
						}
					}
					.admin-dashboard__ticket-request {
						margin: 0 4rem;
						flex: 1;
						display: flex;
						flex-direction: column;
						align-items: center;
						h2 {
							margin-bottom: 2.8rem;
							width: 80%;
						}
						.content {
							flex: 1;
							display: flex;
							flex-direction: column;
							justify-content: space-evenly;
							width: 80%;
							.request-item {
								margin-bottom: 1.2rem;

								.stats {
									display: flex;
									align-items: center;
									margin-bottom: 0.8rem;
									&-label {
										width: 2rem;
										height: 2rem;
										border-radius: 50%;
										margin-right: 0.8rem;
										&.open {
											background: $main-background-gradient;
										}
										&.closed {
											background: linear-gradient(to bottom, #006537, #9bc65f);
										}
									}
								}
							}
						}
					}
					.admin-dashboard__week-chart {
						flex: 1;
						.chart {
							height: 100%;
							display: flex;
							align-items: flex-end;
						}
					}
				}
				// .admin-dashboard__billing {
				// 	@include card-format();
				// 	flex: 1;
				// }
			}
			&.row2 {
				flex: 1;

				.admin-dashboard__orders {
					display: flex;
					flex-direction: column;
					flex: 5;

					@include card-format();

					.list-card {
						flex: 1;
						background: none;
						margin: 0;
						padding: 0;
						.cardItem.header {
							background: $main-background-gradient;
							border-radius: $radius-small;
							margin-bottom: 2rem;
							color: $white;
						}
					}
				}
				.admin-dashboard__lobby-services {
					flex: 2;
					display: flex;
					flex-direction: column;
					overflow: auto;
					.line-chart-with-select {
						flex: 1;
					}
					.lobby-services {
						&__rating {
							@include card-format();
							height: 150px;
							padding: 3rem;
							background: $main-background-gradient;
							justify-content: center;
							align-items: center;
							color: $white;
							h2 {
								margin-bottom: 0.8rem;
							}
						}
					}
				}
			}
		}
	}
}
