//

//	Home component stylesheet
//

.dashboard {
	display: flex;
	flex-direction: column;
	height: 100%;
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: 0.8rem;
		&-content {
			display: flex;
			align-items: center;
			h1 {
				margin-right: 1.2rem;
			}
		}
	}
	.modal {
		position: absolute;
		top: 0;
		left: 0;
		.modal__container {
			box-shadow: $box-shadow-form;
			.attachments {
				label {
					background-color: unset !important;
				}
			}
		}
	}
	&__content {
		flex: 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		&-item {
			display: flex;
			overflow: hidden;

			.chart-group {
				width: 70%;
				display: flex;
				overflow: hidden;
				.chart-card {
					flex: 1;
				}
			}
			.list-group {
				overflow: auto;
				width: 70%;
				display: flex;
				.list-card:first-child {
					flex: 3;
					.cardItem-el {
						width: 20%;
						overflow: hidden;
						&.ticketNumber,
						&.statusLabel {
							word-break: break-all;
						}
					}
				}
				.list-card:last-child {
					flex: 2;

					.numberInfo {
						display: flex;
						align-items: center;
						img {
							margin-right: 1.2rem;
						}
					}
				}
			}
			.events,
			.security-wrapper {
				width: 30%;
				display: flex;
				flex-direction: column;

				.eventsCard {
					flex: 1;
				}
				.security {
					flex: 1;
				}
			}
		}
		&-item:first-child {
			flex: 1;
			max-height: 45%;
		}
		&-item:last-child {
			flex: 1;
		}
	}

	&__chart {
		display: flex;
		&-content {
			padding: 2rem;
		}
	}
}
