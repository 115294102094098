.new-request {
	flex: 1;
	display: flex;
	align-items: center;
	.Mui-disabled {
		color: $white !important;
	}
	.button-text__content {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.4rem;
		img {
			margin-right: 1.2rem;
		}
	}
	&__basic {
		padding: 2rem;
		flex: 1;
		margin-right: 1.2rem;
		&-content {
			@include card-format();
			margin-left: 0;
			margin-top: 2rem;
			h2 {
				margin-bottom: 1.2rem;
			}
			.buttons {
				display: flex;
				justify-content: flex-end;
				.button-container {
					margin: 0 1.2rem;
				}
			}
			.input-field {
				margin-bottom: 2.8rem;
			}
		}
	}
	&__advanced {
		flex: 1;
		margin: 0 1.2rem;
		background: linear-gradient(to bottom, $light-blue, $dark-blue);
		padding: 1.2rem 2rem;
		border-radius: $radius-small;
		label {
			color: $white;
		}
		.select-container {
			margin: unset;
			margin-bottom: 1.6rem;
		}
		input,
		.MuiInputBase-root {
			background: $light-blue;
		}
		input,
		.MuiInputBase-root {
			width: 100%;
			border: unset;
			color: $white;
		}
		.row {
			display: flex;
			justify-content: space-between;
			.select-container {
				width: 48%;
			}
		}
	}
	input,
	.MuiInputBase-root {
		width: 100%;
	}
}
