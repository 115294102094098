//
// Define variables, mixins
//

// Font
@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,700');
@import url('https://fonts.googleapis.com/css?family=Proxima+Nova:400,400i,700');
// MaterialUI Icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// Setting
$default-size: 1rem;

// Color
$primary: #fc4f00;
$secondary: #00325a;

$white: #fff;
$black: #000;

// Material UI color
$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deep-purple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$light-blue: #35a5de;
$dark-blue: #004f8f;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$light-green: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deep-orange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blue-grey: #607d8b;
$dark-gray: #9d9d9d;
$ming-blue: #3d6385;
$green-blue: #9bcec2;
$gray: #9d9d9d;
$lighter-gray: #f7f7f7;
$light-white-gray: #f8f8f8;
$light-gray: #f6f6f6;
$alto-gray: #d3d3d3;
$dark-alto-gray: #d7d7d7;
$white-gray: #ededed;
$darker-white: #f5f5f5;
$app-background: #f8f8f8;
$border-color: rgba(112, 112, 112, 0.24);
$main-background-gradient: linear-gradient(to right, $light-blue, $dark-blue);
// $main-background-gradient: linear-gradient(to right, #35a5de, #004f8f);
$text-gray: #717171;
$tab-title: rgba(0, 0, 0, 0.16);
// Font-family
$bebas-neue: 'bebas-neue';
$montserrat: 'Montserrat';
$proximaNova: 'Proxima Nova';
$font-1: $bebas-neue;
$font-2: $montserrat;

// Font-size
$font-size-multiplier: 1;
$font-size: $default-size * 1.6;
$font-multiplier-base: 0.875;
$font-size-base: $font-size * $font-multiplier-base;
$font-multiplier-large: 1.125;
$font-size-large: $font-size * $font-multiplier-large;
$font-multiplier-small: 0.6875;
$font-size-small: $font-size * $font-multiplier-small;
$font-multiplier-header: 1.4;
$font-size-header: $font-size * $font-multiplier-header;
$font-multiplier-sub-header: 1.25;
$font-size-sub-header: $font-size * $font-multiplier-sub-header;

// Font-weight
$font-thin: 200;
$font-light: 300;
$font-regular: 400;
$font-bold: 600;
$font-bolder: 800;

// Transition
$transition-default: all 0.4s;

// Radius
$radius-default: 0.4rem;
$radius-small: 1rem;
$radius-large: 1.2rem;
$radius-huge: 2rem;

//border
$border-default: 0.2rem solid $light-gray;
$border-button-default: 0.2rem solid $red;
$border-small: 0.1rem solid $light-gray;
$border-active: 0.1rem solid $dark-blue;
// Font weight
$font-weight-normal: 600;

//box shadow
$box-shadow-form: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
	rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
$box-shadow-normal: rgba(0, 0, 0, 0.16) 0px 1px 4px;

// Opacity
$opacity-disabled: 0.4;