.radio__container {
	display: flex;
	align-items: center;
	margin-left: -12px;

	.MuiSvgIcon-root {
		width: 2rem;
		height: 2rem;
	}
}
