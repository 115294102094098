.rating {
	@include card-format();
	&__content {
		margin-top: 1.2rem;
		display: flex;
		justify-content: center;
		span {
			font-size: 5rem;
		}
	}
}
