//
// Typography
//

:root {
	font-size: 62.5%; //1 rem = 8px; 8px/16px = 50%
}

* {
	box-sizing: border-box;
	@include special-scroll-bar();
}
// Make scrolling behaviors smooth on mobile devices
body {
	-webkit-overflow-scrolling: touch;
	overflow: hidden;
	overflow-x: auto;
	height: 100vh;
	@include special-scroll-bar();
	*::-webkit-scrollbar-thumb {
		background: linear-gradient(to bottom, #35a5de, #004f8f);
		width: unset !important;
		height: unset !important;
	}
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
span,
ul,
li,
input,
label,
textarea,
button {
	font-family: $proximaNova, -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
		'Segoe UI Symbol';
	margin: 0;
	display: block;
}
// Force our app to have default font size
// So that UI library will not override it
div,
p,
span,
input,
textarea,
label,
ul {
	font-size: $font-size;
	outline: none;
}

// MaterialUI Icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

i {
	cursor: pointer;
}

// General error message
.error {
	color: $red;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	letter-spacing: 0.1rem;
	line-height: 1.25em;
}
h1 {
	font-size: 6.2 * $default-size;
}
h2 {
	font-size: 2.5 * $default-size;
}
h3 {
	font-size: 1.8 * $default-size;
}
h4 {
	font-size: 1.5 * $default-size;
}
h5 {
	font-size: 1.25 * $default-size;
}
h6 {
	font-size: $default-size;
}

p,
span,
a {
	letter-spacing: 0.05rem;
}

p {
	margin-bottom: 1rem;
}

a {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
		text-underline-position: under;
	}
}
.MuiPopover-root {
	.MuiMenuItem-root {
		font-size: 1.6rem;
	}
}
.empty-list {
	margin: 1.2rem;
}
