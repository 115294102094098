.custom-pagination {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.left-arrow,
	.right-arrow {
		cursor: pointer;
		font-size: 3rem;
		&.disabled {
			cursor: not-allowed;
			color: $gray;
		}
	}
}
