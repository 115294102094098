.chart-note-item {
	display: flex;
	flex: 1;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: 0.4rem;
	.colorPoint {
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 50%;
		margin: 0 2rem 0 0;
		&.undefined {
			background: linear-gradient(to right, #cccccc, #a6a6a6);
		}
		&.green {
			background: green;
		}
		&.lightsalmon {
			background: lightsalmon;
		}
		&.gray {
			background: gray;
		}
		&.slateblue {
			background: slateblue;
		}
		&.pink {
			background: pink;
		}
		&.purple {
			background: purple;
		}
		&.yellow {
			background: yellow;
		}
		&.aqua {
			background: aqua;
		}
		&.fuchsia {
			background: fuchsia;
		}
		&.orange {
			background: orange;
		}
		&.olive {
			background: olive;
		}
		&.gold {
			background: gold;
		}
		&.brown {
			background: brown;
		}
		&.navy {
			background: navy;
		}
		&.red {
			background: red;
		}
		&.supported {
			background: linear-gradient(to right, #9bc65f, #006537);
		}
		&.expiring {
			background: linear-gradient(to right, #ffd244, #c14639);
		}
		&.expired {
			background: linear-gradient(to right, #8d0000, #e50909);
		}
		&.os-supported {
			background: linear-gradient(to right, #637bfd, #0127fb);
		}
		&.os-expiring {
			background: linear-gradient(to right, #388ffe, #026ffa);
		}
		&.os-expired {
			background: linear-gradient(to right, #44c5fa, #00b4fd);
		}
		&.os-unknown {
			background: linear-gradient(to right, #b2b2b2, #6e6e6e);
		}
	}
	.text {
		flex: 1;
		font-size: 1.4rem;
		word-break: break-all;
	}
}
