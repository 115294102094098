.time-log {
	display: flex;
	align-items: center;
	padding: 0.8rem;
	border: 1px solid $gray;
	margin-top: 2rem;
	justify-content: space-between;
	.done {
		margin-right: 2rem;
		background-color: $green;
		color: $white;
	}
	.description {
		flex: 1;
	}
	.time {
		font-weight: bold;
		span {
			text-align: right;
		}
	}
}
