.admin-report {
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	&__header {
		display: flex;
		margin-bottom: 2rem;
		justify-content: space-between;
		align-items: center;
		&-content {
			display: flex;
			align-items: flex-end;
			h1 {
				margin-bottom: -0.25em;
				margin-right: 1.2rem;
			}
			.filter-popover {
				margin: 0 1.2rem;
			}
		}
	}
	&__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		&-row {
			&:first-child {
				max-height: 40%;
			}
			&:last-child {
				flex: 1;
			}
			display: flex;
			justify-content: space-between;
			overflow: hidden;
			.user-report-badge,
			.line-chart-with-select,
			.report-tickets,
			.latest-orders {
				width: 25%;
				.list-card {
					margin: 0;
					padding: 0;
				}
			}
			.report-tickets {
				display: flex;

				.line-chart-with-select {
					flex: 1;
				}
			}
			.user-report-badge {
				@include card-format();
				h2 {
					margin-bottom: 1.2rem;
				}
				&__content {
					flex: 1;
					display: flex;
					justify-content: space-between;

					&-img,
					.user-amount {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						width: 50%;
						margin: 0 2rem;

						p {
							font-weight: bold;
						}
						img {
							width: 12rem;
							height: 12rem;
						}
					}
				}
			}
			.tickets-line-chart {
				@include card-format();
				flex: 1;
			}
			.user-report-list {
				width: 100%;
				@include card-format();

				&__pagination {
					display: flex;
					justify-content: center;
					.custom-pagination {
						width: 10rem;
					}
					.MuiPagination-ul {
						justify-content: center;
						button,
						svg {
							font-weight: bold;
							font-size: 1.6rem;
						}
						.Mui-selected {
							background: $main-background-gradient;
							color: $white;
						}
					}
				}
				&__controllers {
					.search-bar {
						margin-right: 8rem;
						width: 30%;
					}
					.input-field {
						input {
							width: 100%;
							margin-right: -4rem;
						}
					}
					display: flex;
					align-items: center;
					h2 {
						margin-right: 1.2rem;
					}
					.instruction {
						display: flex;
						align-items: center;
						flex: 1;
						justify-content: flex-end;
						color: #093cc7;

						img {
							margin-right: 1.2rem;
						}
					}
					.filter-popover {
						margin-right: 1.2rem;
					}
					.sort-order {
						flex: 1;
						display: flex;
						align-items: center;
						label {
							margin-right: 0.4rem;
							font-weight: bold;
						}
					}
				}
				.list-card {
					box-shadow: unset;
					padding: unset;
					margin: unset;
					margin-bottom: 0.8rem;

					flex: 1;
					.empty-crown {
						width: 2.8rem;
						height: 2.1rem;
						margin-right: 0.8rem;
					}
					.cardItem.header {
						.lastLogin {
							color: black !important;
						}
					}
					.cardItem-el {
						width: 5%;

						overflow: hidden;
						text-align: center;
					}

					.nameLabel {
						width: 8%;
						min-width: 90px;
						flex: unset;
						.user-info {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							width: 100%;
							img {
								margin-right: 0.8rem;
							}
							.MuiAvatar-circle {
								margin-right: 1.2rem;
							}
							.user-name {
								flex: 1;
							}
						}
					}
					.lastLogin {
						color: #093cc7;
						font-weight: bold;
						.last-login-value {
							display: flex;
							.date {
								margin-right: 0.8rem;
							}
							span {
								font-size: 1.4rem;
							}
						}
					}
				}
			}
		}
	}
}
