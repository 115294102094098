.offline-mode {
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	align-content: center;
	h1 {
		text-align: center;
	}
}
