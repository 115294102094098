.admin-companies {
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	&__controllers {
		margin-bottom: 2rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.button-container {
			min-width: 30%;
		}
	}
	&__filter {
		display: flex;
		background: $main-background-gradient;
		padding: 1.2rem;
		border-radius: $radius-small;
		color: $white;
		svg {
			color: $white;
		}
		.checkbox__container {
			margin-right: 3rem;
		}
	}
	&__content {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
		overflow: auto;
		margin-bottom: 1.2rem;
	}
	&__pagination {
		.MuiPagination-ul {
			justify-content: center;
			button,
			svg {
				font-weight: bold;
				font-size: 1.6rem;
			}
			.Mui-selected {
				background: $main-background-gradient;
				color: $white;
			}
		}
	}
}
