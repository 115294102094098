.new-message {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	&__content {
		@include card-format();
		&-form {
			display: flex;
			.basic,
			.message {
				flex: 1;
				margin: 0 1.2rem;
				input {
					width: 100%;
				}
			}
		}
		&-buttons {
			display: flex;
			justify-content: flex-end;
			.button-container {
				margin: 0 1.2rem;
			}
		}
	}
}
