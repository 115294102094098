.loading-skeleton {
	width: 100%;
	padding: 2rem 0;
	.row {
		display: flex;
		margin-bottom: 1.2rem;
		.MuiSkeleton-circle {
			margin-right: 1.2rem;
		}
	}
	span {
		width: 100%;
		margin-bottom: 1.2rem;
	}
}
