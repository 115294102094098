.contact-request-form {
	&__item {
		display: flex;
		.select-container,
		.input-field,
		& > div {
			flex: 1;
			margin: 0.8rem;
			input {
				width: 100%;
			}
		}
	}
}
