.admin-company-badge {
	@include card-format();
	cursor: pointer;
	flex: unset;
	align-items: center;
	justify-content: space-between;
	&.selected {
		border: 1px solid $green;
	}
	&__settings {
		padding: 1.2rem;
		&-item {
			text-align: right;
			cursor: pointer;
			padding: 0.4rem 0.8rem;
			border-radius: $radius-default;
			&:hover {
				background-color: $gray;
				color: $white;
			}
		}
	}
	&__actions {
		width: 100%;
		height: 40px;
		display: flex;
		justify-content: space-between;

		&-access {
			display: flex;
			img {
				width: 2.5rem !important;
				height: 2.5rem !important;
				margin-right: 1.2rem;
			}
		}
	}

	h2 {
		margin-bottom: 1.2rem;
		text-align: center;
		max-height: 10rem;
		width: 100%;
		height: 2.4rem;
		overflow: hidden;
	}
	.address {
		text-align: center;
		color: $gray;
	}
	img {
		width: 3rem;
		height: 4rem;
		margin-bottom: 1.2rem;
	}
	.button-container {
		margin: 1.2rem 0;
	}
}
