.service-requests {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	&__header {
		padding-left: 2.8rem;

		display: flex;
		justify-content: space-between;
		align-items: center;
		&-content {
			display: flex;
			h1 {
				margin-right: 2rem;
			}
		}
		&-buttons {
			display: flex;
			.button-container {
				margin: 0 1.2rem;
			}
		}
		&-tabs {
			flex: 1;
			display: flex;
			justify-content: center;
		}
	}
	&__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
}
