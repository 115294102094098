%share-input {
	border: $border-default;
	outline: none;
	padding: 1.2rem 1.2rem;
	color: black;
	border-radius: $radius-small;
	transition: all 0.2s;
	background-color: $app-background;
	&:focus {
		border: $border-active;
	}
}

.input-field {
	margin-bottom: 1.6rem;

	&.disabled {
		&__input-content,
		&__icon-container,
		input {
			cursor: not-allowed;
		}
	}

	&__input-content {
		display: flex;
		align-items: center;
	}

	&__label {
		margin-bottom: 0.4rem;
		display: block;
		color: $text-gray;
		&.tooltip {
			position: relative;
			&.tooltip:hover {
				.tooltiptext {
					visibility: visible;
				}
			}
		}
		&.tooltip .tooltiptext {
			position: absolute;
			visibility: hidden;
			background-color: black;
			color: #fff;
			text-align: center;
			border-radius: 6px;
			padding: 1rem;

			/* Position the tooltip */
			position: absolute;
			z-index: 1;
		}
	}

	input {
		@extend %share-input;
		$radius-small: 1rem;
		border: 1px solid $gray;
		&:focus {
			outline: none;
		}
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type='number'] {
			-moz-appearance: textfield;
		}
	}
	textarea {
		@extend %share-input;
		width: 100%;
		resize: none;
		$radius-small: 1rem;
		border: 1px solid $gray;
		&:focus {
			outline: none;
		}
	}
	&__icon-container {
		display: flex;
		align-items: center;

		span {
			color: $gray;
			font-size: 2.8rem;
			margin: 0 0.4rem;
			cursor: pointer;
		}
	}

	.error {
		text-align: left;
	}
}
