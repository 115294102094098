.tabs {
	.MuiTabs-root {
		background: #b9b9b9;
		border-radius: $radius-small;
		button {
			background: #b9b9b9;
		}
		button.Mui-selected {
			background: $main-background-gradient;
			color: $white;
			border-radius: $radius-small;
		}
		.MuiTabs-indicator {
			display: none;
		}
	}
}
