.filter-popover {
	display: flex;
	align-items: center;
	&__label {
		margin-right: 2rem;
		font-weight: bold;
	}
	.toggle-el {
		display: flex;
		align-items: center;
		cursor: pointer;
		.text {
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 160px;
			height: 1.2em;
			white-space: nowrap;
		}
	}
	&__content {
		padding: 1.2rem;

		.checkbox__container {
			flex-direction: row-reverse;
			margin: 2rem;
		}
	}
}
