.search-bar {
	position: relative;
	height: 6rem;
	border-radius: $radius-small;
	&__icon {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: $app-background;
		display: flex;
		align-items: center;
		span {
			padding: 1.2rem;
			background: $main-background-gradient;
			border-radius: 50%;
			font-size: 3rem !important;
			color: $white;
			color: pointer;
		}
	}
	.input-field {
		background: $main-background-gradient;
		border-radius: $radius-small;

		-webkit-transition: width 2s ease-in-out;
		-moz-transition: width 2s ease-in-out;
		-o-transition: width 2s ease-in-out;
		transition: width 2s ease-in-out;
		.input-field__input-content {
			flex-direction: row-reverse;
			input {
				background: unset;
				color: $white;
				border: none;
				padding: 2rem;
			}
			.input-field__icon-container {
				span {
					color: $white;
				}
			}
		}
	}
}
