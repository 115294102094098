.request-list {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	&__pagination {
		display: flex;
		justify-content: center;
		.custom-pagination {
			width: 10rem;
		}
	}
	&__tool {
		.input-field {
			width: 50%;
			margin-left: 3rem;
			input {
				width: 100%;
				margin-right: -4rem;
			}
		}
		&-filter {
			display: flex;
			margin: 1.2rem 0 1.2rem 4rem;
			.filter-popover {
				margin-right: 6rem;
			}
		}
	}
	.list-card {
		background: $app-background;
		box-shadow: unset;
		.cardItem.header {
			background: $main-background-gradient;
			border-radius: $radius-small;
			margin-bottom: 2rem;
			color: $white;

			.taskIcon {
				max-width: 3.2rem;
				margin-right: 2rem;
				width: 5%;
			}
		}
		.cardItem-el {
			width: 15%;
			&.priorityLabel,
			&.statusLabel {
				max-width: 15rem;
			}
		}
		.taskIcon {
			max-width: 3.2rem;
			width: 5%;
			margin-right: 2rem;
		}
		.ticketNumber {
			max-width: 20rem !important;
		}
	}
}
