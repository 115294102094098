// Modal style

.modal {
	width: 100%;
	height: 100%;
	z-index: 10000; // force this modal to be above everything else
	display: flex;
	justify-content: center;
	align-items: center;
	&__container {
		background: $white;
		border-radius: $radius-large;
		max-height: 80vh;
		width: 80%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@include card-format();

		.modal-header,
		.modal-content {
			padding: 1.2rem 1.6rem;
		}

		.modal-header {
			border-bottom: $border-small;

			&.with-title {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			&__title {
				font-weight: $font-weight-normal;
				text-transform: uppercase;
			}

			&__close-button {
				color: $gray;
				text-align: right;
				cursor: pointer;
			}
		}

		.modal-content {
			flex: 1;
			overflow-y: auto;

			.action-buttons {
				display: flex;
				justify-content: center;
				align-items: center;

				& > div {
					&:not(:last-child) {
						margin-right: 0.8rem;
					}
				}
			}
		}
	}
}
