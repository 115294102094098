.ticket-form {
	margin: 2rem 0 1.2rem;
	flex: 1;
	display: flex;
	.Mui-disabled {
		color: $light-gray !important;
	}
	.input-field {
		margin-bottom: 2.8rem;
		display: flex;
		label,
		.mui {
			width: 15%;
			word-break: break-all;
			margin-right: 1.2rem;
		}
		.input-field__input-content {
			flex: 1;
		}
	}
	&__basic {
		padding: 2rem;
		flex: 1;
		margin: 0 1.2rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		h2 {
			margin-bottom: 2rem;
		}
		.buttons {
			display: flex;
			margin-top: 1.2rem;

			.button-container {
				margin: 0 1.2rem;
			}
		}
		.attachments {
			display: flex;
			&__label {
				width: 15%;
				min-width: 5rem;
				margin-right: 1.2rem;
				word-break: break-all;
				color: $text-gray;
			}
			&__content {
				flex: 1;
				display: flex;
				flex-wrap: wrap;
				margin-right: 0.8rem;
			}
			label,
			.attachment-icon,
			.attachment-picker__upload-button {
				background-color: $app-background !important;
			}
		}

		.attachment-sign {
			color: $white;
			border-radius: $radius-default;
			cursor: pointer;
			display: flex;

			padding: 0.8rem;
			align-items: center;
			background: linear-gradient(to bottom, $light-blue, $dark-blue);
			span {
				word-break: break-all;
			}
		}
		.attachment-picker-container {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			.attachment-image {
				width: 100%;
				height: 100%;
			}
			img {
				width: 100% !important;
				height: 100% !important;
			}
		}
	}

	&__advanced {
		flex: 1;
		margin: 0 1.2rem;
		padding-right: 2rem;
		border-radius: $radius-small;

		.select-container {
			margin: unset;
			margin-bottom: 1.6rem;
			display: flex;
			label,
			.mui {
				width: 60%;
				word-break: break-all;
				margin-right: 1.2rem;
			}
			.input-field__input-content {
				flex: 1;
			}
		}
		input,
		.MuiInputBase-root {
			background: $gray;
		}
		input,
		.MuiInputBase-root {
			width: 100%;
			border: unset;
		}
		label {
			text-align: right;
		}
		.input-field {
			label {
				width: 35% !important;
			}
		}
		.input-field,
		.select-container {
			align-items: center;
		}
	}
	input,
	.MuiInputBase-root {
		width: 100%;
	}
}
