.third-party-login {
	min-width: 24rem;
	display: flex;
	align-items: center;
	@include box-shadow-default();
    background-color: #FFFFFF;
	border: 1px solid $gray;
	padding: 1.2rem;
    
	img.icon {
		width: 3rem;
		height: 3rem;
		margin: unset;
		margin-right: 1.2rem;
	}
	div {
		flex: 1;
		text-align: center;
        color: $gray;
	}
}
