.login {
	flex: 1;
	background: $app-background;
	display: flex;
	height: 100%;
	&__image {
		flex: 1;
		img {
			width: 100%;
			height: 100%;
		}
	}
	&__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 5rem;
		img {
			width: 15rem;
			height: 15rem;
			margin-bottom: 6rem;
		}
		h2 {
			color: $dark-blue;
			margin-bottom: 2rem;
		}
		&-social {
			display: flex;
			align-items: center;
			margin-bottom: 4rem;

			&__item {
				margin: 2rem;
				a {
					text-decoration: none;
				}
			}
		}
		&-separate {
			height: 1px;
			background: $gray;
			width: 80%;
			position: relative;
			margin-bottom: 4rem;
			&__text {
				position: absolute;
				top: -1.1rem;
				width: 100%;
				text-align: center;
				span {
					display: inline;
					padding: 0 2rem;
					background: $app-background;
					color: rgba(172, 172, 172, 1);
					font-weight: bold;
					font-size: 2rem;
				}
			}
		}
		&-form {
			width: 70%;
			margin-bottom: 4rem;

			.input-field {
				margin-bottom: 0.4rem;
				input {
					color: $gray;
					width: 100% !important;
				}
			}
			.input-field:first-child {
				margin-bottom: 2rem;
			}
			.forgetpassword {
				text-align: right;
				margin-bottom: 2rem;
				span {
					font-size: 1.4rem;
					display: inline;
					cursor: pointer;
				}
			}
			.password-field {
				input {
					margin-right: -3.5rem;
				}
			}
			.button-container {
				width: 100%;
			}
		}
		
	}
}
