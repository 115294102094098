.attachment-icon {
	border: 1px solid #9d9d9d;
	padding: 0.8rem;
	border-radius: $radius-default;
	display: flex;
	align-items: center;
	cursor: pointer;
	img {
		margin-right: 1.2rem;
	}
}
