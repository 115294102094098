.company-information {
	form {
		background-color: $white;
		margin-top: -0.5rem;
		padding: 2rem;
		display: flex;
		.company-information__form {
			flex: 2;
			&-fields {
				display: flex;
				margin: 2rem 0;
				.input-field {
					flex: 1;
					margin: 0.8rem;
					input {
						width: 100%;
					}
				}
				.select-container {
					min-width: 20rem;
				}
			}
			&-fields:last-child {
				margin-bottom: 0;
			}
		}
		.company-information__separate {
			width: 1px;
			background-color: $dark-gray;
			margin: 0 2rem;
		}
		.company-information__advanced {
			display: flex;
			flex: 1;
			flex-direction: column;
			&-content {
				display: flex;
				flex: 1;
				flex-direction: column;
				justify-content: center;
			}
		}
	}
}
