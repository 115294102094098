.contact-requests {
	&__content {
		display: flex;
		.discussion,
		.card-with-tab {
			flex: 1;
		}
		.discussion {
			margin-top: 4rem;
		}
	}
}
