.list-card {
	@include card-format();
	overflow: hidden;
	&__tool {
		margin-bottom: 1.2rem;
		.search-bar {
			width: 50%;
		}
		.input-field {
			margin-bottom: 2rem;
			input {
				width: 100%;
				margin-right: -4rem;
			}
		}
		&-filter {
			display: flex;
			.filter-popover {
				flex: 1;
			}
		}
		.items-per-page,
		.sort-order {
			flex: 1;
			display: flex;
			align-items: center;
			label {
				margin-right: 0.4rem;
				font-weight: bold;
			}
		}
	}
	h2 {
		margin-bottom: 2rem;
	}
	&__content {
		flex: 1;
		overflow-y: auto;
		// @include special-scroll-bar();
	}
	.cardItem {
		display: flex;
		padding: 1.2rem 0;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid $border-color;
		cursor: pointer;
		&.header {
			& > div {
				font-weight: bold;
			}
			&:hover {
				background: unset;
			}
		}
		&-el {
			flex: 1;
			display: flex;
			align-items: center;
			margin: 0 0.4rem;
			white-space: pre-wrap;
			word-break: keep-all; /*this stops the word breaking*/
			word-break: break-all;
		}
		&:hover {
			background: $lighter-gray;
		}
	}
	.cardItem-container {
		&.isSelected {
			margin: 0.4rem 0.4rem 0.4rem;
			border: 1px solid $gray;
			padding: 0 0 1.2rem 0.4rem;
			border-radius: $radius-default;
			.cardItem-container__header {
				display: flex;
				align-items: center;
				.close-icon {
					cursor: pointer;
					margin-right: 0.4rem;
				}
				.cardItem {
					flex: 1;
				}
			}
		}
	}
}
