.card-with-tab {
	display: flex;
	flex-direction: column;
	&__tabs {
		display: flex;
		.item {
			background-color: $white;
			padding: 2rem 1.2rem 2rem;
			margin: 3rem 3rem -0.8rem 3rem;
			z-index: 2;
			width: 20rem;
			text-align: center;
			border-radius: $radius-small;
			cursor: pointer;
			&.active {
				font-weight: bold;
				color: $white;
				background: $main-background-gradient;
			}
		}
	}
	&__content {
		max-height: 100%;
		overflow: auto;
		@include card-format();
		margin-top: -0.5rem;
		z-index: 2;
	}
}
