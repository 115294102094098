//
// Attachment uploader stylesheet
//

.attachment-picker-container {
	&.disabled {
		cursor: not-allowed;

		.attachment-picker {
			cursor: not-allowed;

			&__upload-button {
				label,
				input {
					cursor: not-allowed !important;
				}
			}
		}

		.icon {
			opacity: $opacity-disabled;
		}
	}
	
	#attachment-picker {
		max-width: 10rem;
	}

	.attachment-picker {
		margin-bottom: 1.6rem;
		display: block;
		height: 5rem; // equal to the height of icon mixin

		&__upload-button {
			position: relative;

			input {
				opacity: 0;
			}

			label {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				background: $white;
				display: flex;
				align-items: center;

				.icon {
					@include icon($background: $red);
					margin: auto 0;

					i {
						color: white;
						font-size: 2.4rem;
					}
				}

				.attachments {
					display: flex;
					align-items: center;

					a {
						font-size: 1.2rem;
					}

					i {
						margin-left: 0.8rem;
						cursor: pointer;
					}
				}
			}
		}
	}
	.attachment-image {
		margin-bottom: 1rem;
		text-align: center;
		display: flex;
		justify-content: center;

		img {
			width: auto;
			height: 13rem;
			border-radius: $radius-large;
		}
	}
}
