.ticket-complete {
	position: absolute;
	top: 0;
	left: 0;
	.error {
		text-align: center;
		margin-bottom: 1.2rem;
	}
	.modal__container {
		box-shadow: $box-shadow-form;
		.attachments {
			label {
				background-color: unset !important;
			}
		}
	}
	&__container {
		label {
			color: $gray;
			min-width: 15rem;
		}

		.row {
			display: flex;
			margin: 1.6rem 0;
			justify-content: space-between;
			align-items: flex-start;
			& > span {
				flex: 1;
			}
			.input-field {
				width: 12rem;

				flex-direction: column;
				input {
					margin-right: -36px;
				}
			}
			.billing-label {
				padding-top: 20px;
				display: flex;
				align-items: center;
				height: 70px;
				font-weight: bold;
			}
			.date-picker,
			.billing-label {
				width: 30%;
			}

			.time-picker {
				max-width: 15%;
				width: 12rem;
			}
			.total-time {
				width: 15%;
				&__content {
					font-weight: bold;
					height: 45px;
					display: flex;
					align-items: center;
				}
			}
		}
		.extra-info {
			.input-field {
				flex-direction: column;

				input {
					margin-right: -36px;
				}
			}
		}
		.controls {
			display: flex;
			justify-content: flex-end;
			.button-container {
				margin: 0 1.2rem;
			}
		}
	}
}
