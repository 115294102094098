.latest-orders {
	@include card-format($box-shadow-normal, 2rem, 0.8rem);
	overflow: auto;
	.order-item {
		margin: 2rem 0.4rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		img,.number{
			margin-right: 0.4rem;
		}
	}
}
