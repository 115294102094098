.eventsCard {
	@include card-format();
	overflow: auto;
	h2 {
		margin-bottom: 1.2rem;
	}
	&__content {
		overflow: auto;
		// @include special-scroll-bar();
	}
	.event-item {
		display: flex;
		margin-bottom: 1.2rem;
		span {
			margin-right: 0.8rem;
			background: -webkit-linear-gradient($light-blue, $dark-blue);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		h3 {
			margin-bottom: 0.4rem;
		}
		&__content {
			&-header {
				display: flex;
				align-items: center;
				margin-bottom: 0.4rem;
			}
		}
	}
}
