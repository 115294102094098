.company-detail {
	.button-container {
		margin-bottom: 3.6rem;
	}
	.button-text__content {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.4rem;
		img {
			margin-right: 1.2rem;
		}
	}
	.company-information {
		border-radius: 1rem;
		overflow: hidden;
	}
}
