.company-locations {
	overflow: hidden;
	&__content {
		height: 90%;
		display: flex;
		margin: 2rem;
		flex-wrap: wrap;
		overflow: auto;
	}
	.modal {
		position: absolute;
		top: 0;
		left: 0;
	}
	.location-card {
		flex: 1 0 21%;
		max-width: 21% !important;
	}
}
.location-card {
	@include card-format();
	margin: 2rem;
	img {
		margin-bottom: 1.2rem;
	}
	h3 {
		margin-bottom: 1.2rem;
		text-align: center;
		height: 3rem;
		overflow: hidden;
	}
	align-items: center;
	.button-container {
		margin: 1.2rem;
	}
	&.add-button {
		background: $main-background-gradient;
		justify-content: center;
		align-items: center;
		.add {
			cursor: pointer;
			padding: 0.8rem;
			background: $white;
			font-size: 5rem;
			border-radius: 50%;
			color: $dark-blue;
			margin-bottom: 2rem;
			font-weight: bold;
		}
		.placeholder {
			color: $white;
		}
	}
}
