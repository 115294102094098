.address-badge {
	display: flex;
	padding: 2.4rem;
	background: $main-background-gradient;
	align-items: center;
	color: $white;
	border-radius: $radius-small;
    margin-bottom: 1.2rem;
	img {
		color: $white;
		margin-right: 1.2rem;
	}
    .address{
        font-size: 1.4rem;
        margin-top: 0.8rem;
    }
}
