.training {
	flex: 1;
	display: flex;
	flex-direction: column;
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		&-buttons {
			display: flex;
			.button-container {
				margin: 0 1.2rem;
			}
		}
		&-tabs {
			flex: 1;
			display: flex;
			justify-content: center;
		}
	}
	&__content {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
}
