.chart-card {
	@include card-format();

	overflow: auto;
	&__chart {
		flex: 1;
		display: flex;
		justify-content: space-between;
		overflow: hidden;

		&-title {
			margin-bottom: 2rem;
		}
		&-content {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 50%;
			min-width: 20rem;
			.label {
				position: absolute;
				display: flex;
				flex-direction: column;
				align-items: center;
				.number {
					font-weight: bold;
					font-size: 4rem;
				}
				.text {
					font-size: 1.2rem;
				}
			}
			margin-right: 2rem;
		}
		&-labels {
			width: 40%;
			display: flex;
			flex-direction: column;
			overflow: auto;
			justify-content: flex-start;
			height: 100%;
			.chart-label__group {
				margin-bottom: 1.2rem;
				h3 {
					font-weight: 700;
					word-break: break-all;
					margin-bottom: 1.2rem;
				}
				// .chart-label__item {
				// 	display: flex;
				// 	flex: 1;
				// 	align-items: flex-start;
				// 	justify-content: flex-start;
				// 	flex-wrap: wrap;
				// 	padding: 0.4rem;
				// 	.colorPoint {
				// 		width: 1.5rem;
				// 		height: 1.5rem;
				// 		border-radius: 50%;
				// 		margin: 0 2rem 0 0;
				// 	}
				// 	.text {
				// 		flex: 1;
				// 		font-size: 1.4rem;
				// 		word-break: break-all;
				// 	}
				// }
			}
		}
	}
}
