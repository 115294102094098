[class*='-colorPrimary'] {
	background-color: $main-background-gradient;
}

[class*='-colorSecondary'] {
	background-color: $main-background-gradient;
}

.Mui-disabled {
	fill: $gray !important;
	color: $gray !important;
}
