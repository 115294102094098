.training-item {
	@include card-format();
	padding: 3rem;
	flex-direction: row;
	max-width: 80rem;
	overflow: hidden;
	&__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		margin-right: 1.2rem;

		&-header {
			display: flex;
			align-items: center;
			margin-bottom: 1.2rem;
			img {
				margin-right: 3.2rem;
			}
			.header-content {
				flex: 1;
				h2 {
					margin-bottom: 0.4rem;
				}
				&__stats {
					display: flex;
					.stats-item {
						display: flex;
						align-items: center;
						margin-right: 5rem;
						span {
							margin-right: 0.8rem;
						}
						&__text {
							color: $gray;
						}
					}
				}
			}
		}
		&-body {
			height: 10rem;
			overflow: auto;
			text-overflow: ellipsis;
		}
	}
}
