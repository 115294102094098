.contact-request-info {
	label {
		width: 15rem;
		font-weight: bold;
	}
	&__row {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin: 1.2rem 0;
	}
	&__message {
		margin: 1.2rem 0;
	}
}
