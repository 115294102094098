.select-container {
	margin: 0.8rem;
	.MuiSvgIcon-root {
		width: 3rem !important;
		height: 3rem !important;
	}
	label {
		color: $text-gray;
		margin-bottom: 0.4rem;
		font-size: $font-size;
		height: 2rem;
	}
	.select {
		background: $white;
		border-radius: 0.8rem;
		padding: 0.8rem 0.8rem;
		border: 1px solid $gray;
		background-color: $app-background;
		height: 4.6rem;
		font-size: 1.6rem;
		&:focus,
		&:active {
			border: $border-active;
		}

		// Default select icon style
		svg {
			top: auto;
		}

		// Remove default Material UI select border bottom
		&::before,
		&::after {
			display: none !important;
		}
	}
}
