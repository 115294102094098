.company-search {
	display: flex;
	align-items: center;
	&__label {
		margin-right: 2rem;
		font-weight: bold;
	}
	.toggle-el {
		display: flex;
		align-items: center;
		cursor: pointer;
		.text {
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 160px;
			height: 1.2em;
			white-space: nowrap;
		}
	}
	&__content {
		padding: 1.2rem;
		display: flex;
		flex-direction: column;
		&-companies {
			max-height: 50vh;
			max-width: 25rem;
			overflow: auto;
			margin: 1.2rem 0 0.4rem;
		}

		.company-item {
			margin: 1.2rem 0.8rem;
			padding: 1.2rem 0.8rem;
			cursor: pointer;
			&.selected {
				background: $gray;
				border-radius: $radius-small;
			}
			&:hover {
				background: $gray;
				border-radius: $radius-small;
			}
		}
	}
}
