.color-label {
	color: $white;
	padding: 0.8rem;
	border-radius: $radius-small;
	font-size: 1.4rem;
	max-height: 4rem;
	width: 100%;
	max-width: 17rem;
	display: flex;
	justify-content: center;
	align-items: center;
	//os
	&.os-supported {
		background: linear-gradient(to right, #68ddfc, #05c8fb);
	}
	&.os-expiring {
		background: linear-gradient(to right, #ee79f7, #e60bf7);
	}
	&.os-expired {
		background: linear-gradient(to right, #fb956c, #f94a04);
	}
	&.os-unknown {
		background: linear-gradient(to right, #5599fb, #0065f8);
	}
	&.undefined {
		background: linear-gradient(to right, #cccccc, #a6a6a6);
		color: $black;
	}
	&.supported {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.expiring {
		background: linear-gradient(to right, #ffd244, #c14639);
	}
	&.expired {
		background: linear-gradient(to right, #8d0000, #e50909);
	}

	&.aulaRole {
		background: linear-gradient(to right, #351e6b, #d13d75);
	}
	&.ecommerceRole {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.autotaskRole {
		background: linear-gradient(to right, #ffd244, #c14639);
	}

	&.billing_contact {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.purchasing {
		background: linear-gradient(to right, #ffd244, #c14639);
	}
	&.technical {
		background: linear-gradient(to right, #ffd244, #c14639);
	}

	&.critical {
		background: linear-gradient(to right, #8d0000, #e50909);
	}
	&.high {
		background: linear-gradient(to right, #8d0000, #e50909);
	}
	&.medium {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.low {
		background: linear-gradient(to right, #ffd244, #c14639);
	}
	&.inProgress {
		background: linear-gradient(to right, #ffd244, #c14639);
	}
	&.done {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.subscriber {
		background: linear-gradient(to right, #ffd244, #c14639);
	}
	&.preOrder {
		background: linear-gradient(to right, #ffd244, #c14639);
	}
	&.aula_viewer {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.customerResponded {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.new {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.waitingApproval {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.dispatched {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.changeOrder {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.customerNoteAdded {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.inProgress {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.escalate {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.waitingMaterial {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.waitingCustomer {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.waitingVendor {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.onHold {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.billed {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.inActive {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.readyToBill {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.complete {
		background: linear-gradient(to right, #9bc65f, #006537);
	}
	&.confirmed {
		background: linear-gradient(to right, #c14639, #ffd244);
	}
	&.in_delivery {
		background: linear-gradient(to right, #07866e, #6cffb5);
	}
	&.delivered {
		background: linear-gradient(to right, #006537, #9bc65f);
	}
	&.invoiced {
		background: linear-gradient(to right, #35a5de, #004f8f);
	}
}
