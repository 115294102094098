.devices {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	&__pagination {
		display: flex;
		justify-content: center;
		.custom-pagination {
			width: 10rem;
		}
	}
	&__header {
		display: flex;
		margin: 0.8rem 0.8rem 0;

		&-content {
			flex: 1;
			margin-right: 1.2rem;
			&__title {
				display: flex;
				h1 {
					margin-right: 2rem;
				}
			}
			.devices__charts {
				display: flex;
				.chart-card {
					flex: 2;
					@include card-format(unset, 0, 0);
					background: unset;
					min-width: 30rem;
					margin-right: 1.2rem;
				}
				.device-types {
					flex: 3;
					&__content {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 100%;
						flex-wrap: wrap;
					}
					.device-type {
						width: 11rem;
						height: 11rem;
						border-radius: 50%;
						border: 5px solid $gray;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						margin: 0.8rem;
						padding: 0.4rem;
						word-break: break-all;
						text-align: center;
					}
				}
			}
		}
		&-buttons {
			.button-container {
				margin-bottom: 1.2rem;
				.button-text {
					height: 46px;
				}
			}
			.button-text__content {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 1.4rem;
				img {
					margin-left: 1.2rem;
				}
			}
		}
	}
	.list-card {
		padding: unset;
		background: $app-background;
		box-shadow: unset;
		&__tool {
			margin: unset;
		}
		.cardItem.header {
			padding: 1.2rem 0.4rem;
			background: $main-background-gradient;
			border-radius: $radius-small;
			margin-bottom: 2rem;
			color: $white;
		}
		.cardItem-el {
			width: 10%;

			&.model,
			&.system,
			&.age,
			&.warranty {
				max-width: 8rem;
			}
			&.age,
			&.totalphysicalmemory,
			&.physicaldrive {
				max-width: 10rem;
				justify-content: center;
			}
			&.lastloggedinuser {
				word-break: break-all;
				min-width: 15rem;
			}
		}
		.deviceIcon {
			max-width: 5rem;
		}
	}
}
