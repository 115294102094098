.order-list {
	flex: 1;
	display: flex;
	flex-direction: column;
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: 0.8rem;
		&-content {
			display: flex;
			align-items: center;
			h1 {
				margin-right: 1.2rem;
			}
		}
	}
	&__pagination {
		display: flex;
		justify-content: center;
		.custom-pagination {
			width: 10rem;
		}
	}
	.numberInfo {
		display: flex;
		align-items: center;
		img {
			margin-right: 1.2rem;
		}
	}
	.list-card {
		flex: 1;
		background: $app-background;
		box-shadow: unset;
	}
	.list-card {
		background: $app-background;
		box-shadow: unset;
		.isSelected{
			border:none
		}
		.cardItem.header {
			padding: 1.2rem 0.4rem;
			background: $main-background-gradient;
			border-radius: $radius-small;
			margin-bottom: 2rem;
			color: $white;
		}
		.cardItem-el {
			width: 10%;
			overflow: hidden;
			text-align: center;
		}
	}
	.order-product-list {
		h2 {
			margin: 1.2rem;
		}
		.list-card {
			padding: 0;
			.deviceInfo {
				display: flex;
				justify-content: space-between;
				align-items: center;
				img {
					margin-right: 2rem;
				}
			}
		}
	}
}
