.line-chart-with-select {
	@include card-format();
	justify-content: center;
	overflow-y: auto;
	overflow-x: hidden;
	&__header {
		display: flex;
		align-items: flex-start;
		margin-bottom: 1.2rem;
		h2 {
			flex: 2;
		}
		.select-container {
			margin: 0;
			flex: 1;
			display: flex;
			justify-content: flex-end;
			.select {
				border: none !important;
			}
		}
	}
}
