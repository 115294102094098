.user-management {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	justify-content: center;
}
.user-list {
	flex: 1;
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	.company-button {
		.button-text {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.modal {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	.attachment-picker {
		display: none;
	}
	&__pagination {
		display: flex;
		justify-content: center;
		.custom-pagination {
			width: 10rem;
		}
	}
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 2.8rem;
		&-buttons {
			display: flex;
			.button-container {
				margin: 0 1.2rem;
				.button-text {
					height: 46px;
				}
			}
		}
		.button-text__content {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.4rem;
			img {
				margin-left: 1.2rem;
			}
		}
	}

	.list-card {
		background: $app-background;
		box-shadow: unset;
		.cardItem.header {
			background: $main-background-gradient;
			border-radius: $radius-small;
			margin-bottom: 2rem;
			color: $white;
		}
		.cardItem-el {
			width: 10%;
			overflow: hidden;
			&.aulaRoleLabel,
			&.ecommerceRoleLabel,
			&.autotaskRoleLabel {
				max-width: 14rem;
			}
		}
		.checkbox {
			max-width: 3rem;
		}
	}

	.user-group-edit__container {
		&-header {
			margin-bottom: 1.2rem;
			li {
				display: list-item;
				margin-bottom: 0.4rem;
			}
			.description {
				margin-bottom: 0.8rem;
			}
		}
		&-footer {
			display: flex;
			width: 100%;
			justify-content: center;
			margin-top: 2rem;
		}
	}
}
#menu-companyLocationID,
#menu-aulaRole,
#menu-ecommerceRole,
#menu-contactID,
#menu-status,
#menu-priority,
#menu-issueType,
#menu-subIssueType,
#menu-billingCodeID #menu-autotaskRole {
	z-index: 130000 !important;
}
.MuiPagination-ul {
	justify-content: center;
	button,
	svg {
		font-weight: bold;
		font-size: 1.6rem;
	}
	.Mui-selected {
		background: $main-background-gradient;
		color: $white;
	}
}
