.discussion-item {
	display: flex;
	&__submit,
	&__avatar {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-right: 1.2rem;
		.name {
			margin-top: 0.8rem;
		}
		width: 10rem;
		.submitIcon {
			color: $white;
			background: $main-background-gradient;
			padding: 2rem;
			border-radius: 50%;
			cursor: pointer;
		}
	}
	&__content {
		flex: 1;
		@include card-format();
		margin-top: 0;
		.input-field {
			input {
				background-color: $white;
				border: unset;
			}
		}
	}
}
