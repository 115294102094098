// Core layout stylesheet

#app {
	height: 100vh;
	.core-layout {
		display: flex;
		height: 100%;

		&__nav {
			flex: 1;
		}
		main {
			position: relative;
			overflow-y: hidden;
			flex: 6;
			padding: 2rem;
			background: $app-background;
			display: flex;
			flex-direction: column;
			min-width: 110rem;
		}
	}
}
