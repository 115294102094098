.security {
	@include card-format();
	overflow: hidden;
	padding: 2rem;
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		word-break: break-all;
		span {
			color: $purple;
		}
		margin-bottom: 1.2rem;
	}
	&__link {
		padding-top: 1.2rem;
		h3 {
			text-decoration: underline;
		}
	}
	&__content {
		overflow: auto;
		// @include special-scroll-bar();
		&-item {
			border-bottom: 1px solid $gray;
			margin-bottom: 0.8rem;

			.time,
			.title,
			.content {
				margin-bottom: 0.4rem;
			}
			.time {
				color: $gray;
			}
			.title {
				color: #0323a7;
				cursor: pointer;
				font-size: 1.8rem;
			}
		}
	}
}
