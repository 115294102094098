.reset-password {
	flex: 1;
	background: $app-background;
	display: flex;
	height: 100%;
	&__image {
		flex: 1;
		img {
			width: 100%;
			height: 100%;
		}
	}
	&__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 5rem;
		h2 {
			color: $dark-blue;
			margin-bottom: 2rem;
			text-align: center;
		}
		form {
			width: 70%;
		}
		input,
		.button-container {
			width: 100%;
		}
        input{
            margin-right: -5rem;
        }
	}
}
