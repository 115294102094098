.location-form {
	&__item {
		display: flex;
		input {
			width: 100%;
		}
		.input-field,
		.select-container {
			flex: 1;
			margin: 0.8rem 1.2rem;
		}
		.column {
			flex: 1;
			.checkbox__container {
				margin: 0.8rem 1.2rem;
			}
		}
		.row {
			display: flex;
		}
	}
	&__control {
		display: flex;
		justify-content: flex-end;
		margin: 1.2rem 0;
		.button-container {
			margin: 0 1.2rem;
		}
	}
}

#menu-country {
	z-index: 130000 !important;
}
