.error-boundary {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
    align-items: center;
    h1{
        margin-bottom: 2rem;
    }
}
