//
// Mixins
//
/**
 * Breakpoints
 *
 * 1em = 16px
 * phone
 * tab-port
 * tab-land
 * desktop
 * big-desktop
 */
@mixin breakpoint($view) {
	@if ($view == small-phone) {
		@media (max-width: 25em) {
			@content; // 400px
		}
	}

	@if ($view == phone) {
		@media (min-width: 25rem) and (max-width: 37.5em) {
			@content; // 400px - 600px
		}
	}

	@if ($view == tab-port) {
		@media (min-width: 37.5em) {
			@content; // 600px
		}
	}

	@if ($view == tab-land) {
		@media (min-width: 56.25em) {
			@content; // 900px
		}
	}

	@if ($view == desktop) {
		@media (min-width: 1400px) {
			@content; // 1200px
		}
	}

	@if ($view == desktop-large) {
		@media (min-width: 112.5em) {
			@content; // 1800px
		}
	}
}

@mixin button-container-default(
	$flex-direction: row,
	$justify-content: center,
	$align-items: center
) {
	display: flex;
	flex-direction: $flex-direction;
	justify-content: $justify-content;
	align-items: $align-items;
	width: fit-content;
}

@mixin button-default($background-color: $main-background-gradient) {
	display: flex;
	background: $background-color;
	justify-content: center;
	align-items: center;
	transition: $transition-default;
	border: 0;
	border-radius: $radius-small;
	width: 100%;
	color: $white;
	box-shadow: 0 0.5rem 1rem rgba($black, 0.15);

	&:hover {
		cursor: pointer;
		opacity: 0.7;
	}
	&:disabled {
		cursor: not-allowed;
		opacity: 1;
	}

	&:hover,
	&:focus {
		box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);
		outline: none;
	}
}

@mixin box-shadow-default(
	$x: 0,
	$y: 4px,
	$blur: 20px,
	$spread: 0px,
	$color: rgba(0, 0, 0, 0.1)
) {
	box-shadow: $x $y $blur $spread $color;
}

@mixin card-format(
	$box-shadow: $box-shadow-normal,
	$padding: 2rem,
	$margin: 0.8rem
) {
	padding: $padding;
	margin: $margin;
	background: $white;
	display: flex;
	flex-direction: column;
	border-radius: $radius-small;
	box-shadow: $box-shadow;
}
@mixin special-scroll-bar() {
	&::-webkit-scrollbar {
		width: 0.5em;
		height: 0.5em;
		&:horizontal {
			width: 0.9em;
			height: 0.9em;
		}
	}

	&::-webkit-scrollbar-thumb {
		background: linear-gradient(to bottom, $light-blue, $dark-blue);
		border-radius: 3px;
	}
}
// Select icon
@mixin select-icon($width: 4rem, $height: 4rem, $top: -0.4rem) {
	color: $red;
	width: $width;
	height: $height;
	top: $top;
}

// Icons
@mixin icon(
	$width: 5rem,
	$height: 5rem,
	$background: $white,
	$color: $red,
	$padding: 0.8rem
) {
	width: $width;
	height: $height;
	border-radius: 50%;
	background: $background;
	color: $color;
	// Align the icon inside its container
	display: flex;
	justify-content: center;
	align-items: center;

	padding: $padding;
	margin: 0 auto;
	cursor: pointer;
}
