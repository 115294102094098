.user-forms {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: auto;
	&__header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 4rem;
	}
	.button-text__content {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.4rem;
		img {
			margin-right: 1.2rem;
		}
	}
	&__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		.card-with-tab {
			flex: 1;
			overflow: hidden;
		}
	}
}
#menu-testing {
	z-index: 130000 !important;
}
